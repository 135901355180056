body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.video-container-styles {
  width: 100%;
  margin: 0 auto;
}

.playlist-queue-styles {
  margin-top: 0.5%;
  border-radius: 99em;
  display: flex;
  align-items: center;
  overflow-x: auto;
  background-color: #212836;
}

.playlist-queue-item-styles {
  margin: 5px;
  cursor: pointer;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid transparent;
  border-radius: 99em;
}

.current-playing-video-styles {
  box-shadow: 0px 0px 5px white;
  transition: 0.5s;
}

.thumbnail-styles {
  width: 100%;
  height: 100%;
}

.video-styles {
  width: 100%;
  height: auto;
  object-fit: scale-down;
}

@media screen and (max-width: 549px) {
  .video-container-styles {
    width: 100%;
  }
}
